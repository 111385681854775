// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ca-nhan-hoat-dong-thuong-mai-js": () => import("./../../../src/pages/ca-nhan-hoat-dong-thuong-mai.js" /* webpackChunkName: "component---src-pages-ca-nhan-hoat-dong-thuong-mai-js" */),
  "component---src-pages-dang-ky-thanh-lap-cong-ty-js": () => import("./../../../src/pages/dang-ky-thanh-lap-cong-ty.js" /* webpackChunkName: "component---src-pages-dang-ky-thanh-lap-cong-ty-js" */),
  "component---src-pages-dich-vu-cam-do-js": () => import("./../../../src/pages/dich-vu-cam-do.js" /* webpackChunkName: "component---src-pages-dich-vu-cam-do-js" */),
  "component---src-pages-giup-do-thanh-lap-cong-ty-tai-chinh-js": () => import("./../../../src/pages/giup-do-thanh-lap-cong-ty-tai-chinh.js" /* webpackChunkName: "component---src-pages-giup-do-thanh-lap-cong-ty-tai-chinh-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nganh-nghe-cho-thue-xe-js": () => import("./../../../src/pages/nganh-nghe-cho-thue-xe.js" /* webpackChunkName: "component---src-pages-nganh-nghe-cho-thue-xe-js" */),
  "component---src-pages-quy-dinh-tong-hop-doanh-nghiep-js": () => import("./../../../src/pages/quy-dinh-tong-hop-doanh-nghiep.js" /* webpackChunkName: "component---src-pages-quy-dinh-tong-hop-doanh-nghiep-js" */),
  "component---src-pages-thanh-lap-cong-ty-an-ninh-hang-khong-js": () => import("./../../../src/pages/thanh-lap-cong-ty-an-ninh-hang-khong.js" /* webpackChunkName: "component---src-pages-thanh-lap-cong-ty-an-ninh-hang-khong-js" */),
  "component---src-pages-thanh-lap-cong-ty-von-nuoc-ngoai-js": () => import("./../../../src/pages/thanh-lap-cong-ty-von-nuoc-ngoai.js" /* webpackChunkName: "component---src-pages-thanh-lap-cong-ty-von-nuoc-ngoai-js" */),
  "component---src-pages-thanh-lap-cong-ty-xuat-nhap-khau-js": () => import("./../../../src/pages/thanh-lap-cong-ty-xuat-nhap-khau.js" /* webpackChunkName: "component---src-pages-thanh-lap-cong-ty-xuat-nhap-khau-js" */),
  "component---src-pages-tong-hop-phap-luat-thanh-lap-cong-ty-js": () => import("./../../../src/pages/tong-hop-phap-luat-thanh-lap-cong-ty.js" /* webpackChunkName: "component---src-pages-tong-hop-phap-luat-thanh-lap-cong-ty-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-xac-nhan-cua-nhan-chung-js": () => import("./../../../src/pages/xac-nhan-cua-nhan-chung.js" /* webpackChunkName: "component---src-pages-xac-nhan-cua-nhan-chung-js" */)
}

